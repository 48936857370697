import styled, { css } from "styled-components";
import { baseTheme } from "../../../Style/baseTheme";

export const Section = styled.section`
    padding: 20PX;
    position: relative;
`;

export const Container = styled.div`
`;
export const BackWrapper = styled.div`
    margin-bottom:30px;
`;

export const PrivacyTitle = styled.h1`
    font-size: 25px;
    margin-bottom: 14px;
    color: ${baseTheme.colors.privacyTitle};
`;

export const PrivacyUl = styled.ul`

`;

export const PrivacyLi = styled.li`

`;

export const PrivacySubTitle = styled.h2`
    font-size: 20px;
    line-height: 24px;
    color: ${baseTheme.colors.privacyTitle};
`;

export const PrivacyText = styled.p`
    margin: 15px 0 50px 0;
    font-size: 16px;
    line-height: 25.6px;
    color: ${baseTheme.colors.privacyText};
`;
export const SkeletonLoader = styled.div`
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
    overflow: hidden;
    animation: load 1.4s infinite linear;

    @keyframes load {
        0% {
            background-position: -200%;
        }
        100% {
            background-position: 200%;
        }
    }
`;